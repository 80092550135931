import Vue                   from 'vue';
import Vuex                  from 'vuex';
import createMultiTabState   from 'vuex-multi-tab-state';
import createPersistedState  from 'vuex-persistedstate';
import Cookies               from 'js-cookie';
import ui                    from './ui/';
import auth                  from './auth/';
import admin                 from './admin/';
import browseCourses         from './browseCourses/';
import lesson                from './lesson/';
import simulator             from './simulator/';
import simulatorControls     from './simulatorControls/';
import quiz                  from './quiz/';
import dealLibrary           from './dealLibrary/';
import screenRecorder        from './screenRecorder/';
import simulatorParameters   from './simulatorParameters/';
import gameEngine            from './gameEngine/';
import { getAuthStateFromCookies, toNumber } from '../helpers';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

Vue.config.devtools = debug;

const LS_STORE_KEY = 'bridge128';
const COOKIE_AUTH_STORE_KEY = 'auth';
const TOKEN_PATH = 'auth.token';
const COOKIE_EXPIRATION_DAYS = process.env.VUE_APP_COOKIE_EXPIRATION_DAYS;
const BRIDGE128_DOMAIN = process.env.VUE_APP_BRIDGE128_DOMAIN || 'localhost';

export default new Vuex.Store({
	modules: {
		ui,
		auth,
		admin,
		browseCourses,
		lesson,
		simulator,
		simulatorControls,
		quiz,
		dealLibrary,
		screenRecorder,
		simulatorParameters,
		gameEngine
	},
	plugins: [
		createPersistedState({
			key: COOKIE_AUTH_STORE_KEY,
			paths: [TOKEN_PATH],
			filter (mutation) {
				return [
					'auth/setToken',
					'auth/logOut'
				].includes(mutation.type);
			},
			storage: {
				getItem: key => {
					const state = getAuthStateFromCookies(
						COOKIE_AUTH_STORE_KEY
					);

					if (state) {
						return JSON.stringify({ [key]: state });
					}
				},
				removeItem: key => Cookies.remove(key, {
					path: '/',
					domain: BRIDGE128_DOMAIN
				}),
				setItem: (key, value) => {
					const payload = value ? JSON.parse(value)[key] : null;

					if (payload) {
						const cookieMaxAgeInDays = toNumber(COOKIE_EXPIRATION_DAYS, 30);
						Cookies.set(key, JSON.stringify(payload), {
							'Max-Age': String(60 * 60 * 24 * cookieMaxAgeInDays),
							path: '/',
							domain: BRIDGE128_DOMAIN
						});
					}
				}
			}
		}),
		createMultiTabState(({
			key: LS_STORE_KEY,
			statesPaths: [
				// TODO: Do we really need to share these values between multiple tabs?
				//  Let's disable it until we find a better way
				//  to implement remote control for the simulator
				//  because it causes additional bugs.
				// 'simulator.currentGameData',
				// 'simulator.gameStates',
				// 'simulator.currentGameStateIndex',
				// 'simulator.hiddenCards',
				// 'simulator.highlightingModeIsEnabled',
				// 'simulator.highlightedCards',
				// 'simulator.rolesAreVisible',
				// 'simulator.DDSScoresAreVisible',
				// 'simulator.faceDownCards',
				// 'simulator.tablePositions',
				// 'simulatorControls.remoteControlWindowIsOpen',
				// 'dealLibrary.groups',
				// 'dealLibrary.currentPage',
				// 'dealLibrary.numPages',
				// 'dealLibrary.totalItems',
				// 'dealLibrary.searchString',
				// 'dealLibrary.activeFilters',
				// TODO: ...
				'ui.seenTourSteps'
			]
		}))
	],
	strict: debug
	// strict: false
});
