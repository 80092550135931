export default {

	setContext (state, { context }) {
		state.context = context;
	},

	clearContext (state) {
		state.context = undefined;
	},

	setCourseId (state, { courseId }) {
		state.courseId = courseId;
	},

	clearCourseId (state) {
		state.courseId = undefined;
	},

	setLesson (state, { lesson }) {
		state.lesson = lesson;
	},

	clearLesson (state) {
		state.lesson = undefined;
	},

	v2SetLesson (state, lesson) {
		state.v2Lesson = lesson;
	}

};
