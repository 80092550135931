import posthog from 'posthog-js';

export default {
	install (Vue, options) {
		Vue.prototype.$posthog = posthog.init(
			process.env.VUE_APP_POSTHOG_API_KEY,
			{
				api_host: process.env.VUE_APP_POSTHOG_API_HOST || 'https://eu.i.posthog.com',
				capture_pageview: false
			}
		);
	}
};
