import api from '@/services/api';
import { openExternalLink } from '@/helpers';

export default {
	async getRoomToken ({ state, commit }, {
		mode,
		dealIds,
		exitRedirectUrl,
		dealRecordingEnabled = [],
		startWithEmptyAuction = [],
		metadata
	}) {
		if (state.loading) {
			return;
		}
		commit('setLoading', true);

		const { roomToken } = await api.deals.createRoom({
			mode,
			dealIds,
			exitRedirectUrl,
			dealRecordingEnabled,
			startWithEmptyAuction,
			metadata
		});
		commit('setLoading', false);
		return roomToken;
	},

	openGameEngine ({ state }, { roomToken, newTab = true }) {
		if (state.gameEngineUrl && roomToken) {
			openExternalLink(state.gameEngineUrl, { roomToken }, newTab ? '_blank' : '_self');
		}
	},

	openHandle ({ state }, { isAuth = false, newTab = true }) {
		if (state.handleUrl) {
			openExternalLink(`${state.handleUrl}/${isAuth ? 'play' : 'tournament'}`, newTab ? '_blank' : '_self');
		}
	}
};
