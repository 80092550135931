import api from '@/services/api';

export default {

	async setActivity ({ commit, dispatch, getters }, {
		context,
		courseId,
		lessonId,
		activityId
	}) {
		commit('setContext', { context });
		commit('setCourseId', { courseId });
		if (context === 'teaching') {
			await dispatch('setTeachingActivity', {
				lessonId,
				activityId
			});
		} else 	if (context === 'admin') {
			await dispatch('setAdminActivity', {
				lessonId,
				activityId
			});
		} else {
			await dispatch('setStudentActivity', {
				lessonId,
				activityId
			});
		}
		if (getters.getLessonId !== lessonId) {
			await dispatch('setLesson', { lessonId });
		}
		if (getters.getActivityType !== 'quiz') {
			return;
		}
		dispatch('quiz/setQuiz', {
			quiz: getters.getActivity
		}, { root: true });
	},

	async setStudentActivity ({ commit, getters }, {
		activityId,
		lessonId
	}) {
		const activity = await api.courses.getActivityByIdAndLessonId({
			lessonId,
			activityId
		});
		if (!activity) {
			commit('ui/showError', null, { root: true });
			return false;
		}
		commit('setActivity', {
			activity
		});
	},

	async setTeachingActivity ({ commit, getters }, {
		activityId,
		lessonId
	}) {
		const activity = await api.teaching.getActivityByIdAndLessonId({
			lessonId,
			activityId
		});
		if (!activity) {
			commit('ui/showError', null, { root: true });
			return false;
		}
		commit('setActivity', {
			activity
		});
	},

	async setAdminActivity ({ commit, getters }, {
		activityId,
		lessonId
	}) {
		const activity = await api.admin.getActivityByIdAndLessonId({
			lessonId,
			activityId
		});
		if (!activity) {
			commit('ui/showError', null, { root: true });
			return false;
		}
		commit('setActivity', {
			activity
		});
	},

	async setActivityProgress ({ getters, commit }, { progress }) {

		console.log('Me 1');

		console.log(getters.getContext);

		if (getters.getContext === 'myCourses') {
			await api.courses.setActivityProgressByIdAndLessonId({
				lessonId: getters.getLessonId,
				activityId: getters.getActivityId,
				progress
			});
		}
		commit('setActivityProgress', { progress });
	}

};
